@import url(https://fonts.googleapis.com/css2?family=Varela+Round&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Varela+Round&display=swap);

/* .login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
} */

.login-wrapper {
    flex: 4 1;
    text-align: center;
    background-color: #182636;
    height:100vh;
    width:100vw;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-card {
  /* margin: auto;
  position: absolute;
  top: 50%; left: 0; bottom: 0; right: 0; */
  width:20vw;
}

.login-card > *{
  margin:1.5em 0;
}

.white-text{
  color:white;
}

.orange-link{
  color:#EEA83B;
}

.bolder{
  font-weight:bolder;
}

.agilityLogo{
  width:70%;
  display: block;
  margin:auto;
}
.zbrains-logo{
  height:30px;
}

.zbrains{
  /* height:100px; */
  /* color:white; */
  font-family: 'Varela Round', sans-serif;
  display:flex;
  justify-content: center;
  align-items: center;
  /* line-height: 100px;
  vertical-align: middle;
  text-align: center; */
}

.zbrains .bigger{
  font-size: large;
}

.login-featured {
    width: 25%;
    display: flex;
    justify-content: space-between;
    height: 25vh;
  }
  
  .login-featuredItem {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    flex: 1 1;
    margin: 0px 20px;
    padding: 30px;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  }

  .login-title{
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 40px;
      font-family: fantasy;
  }

.login-button {
    background-color: #5BA850;
    text-align: center;
    border: none;
    border-radius: 5px;
    color: white;
    padding: 15px 32px;
    text-decoration: none;
    font-size: 16px;
    letter-spacing: 2px;
    font-weight: bolder;
    cursor: pointer;
    border-radius: 3px;
  }

  .login-button:hover {
      background-color: #3e7337;
  }

  .error{
      font-size: 0.8rem;
      color: #bb0000;
  }
.callback{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 25%;
    margin: auto;
    height: 100vh;
}

.callback-loading-text{
    font-size: 15px;
    font-family: monospace;
    margin-bottom: 6px;
}

.container{
    display: flex;
    background-color: #F1F4F5;
    /* display: inline-block; */
    /* margin-top: 10px; */
}

.white-background{
  background-color:white;
}

.link{
    text-decoration: none;
    color: inherit;
}
.chart {
    margin: 20px;
    padding: 20px;
    background-color: white;
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  }
  
  .chartTitle {
    margin-bottom: 20px;
  }
.featured {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  
  .featuredItem {
    display: flex;
    justify-content: space-between;
    flex: 1 1;
    background-color: white;
    margin: 0px 20px;
    padding: 30px;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  }
  
  .featuredTitle{
      font-size: 20px;
  }
  
  .featuredMoneyContainer{
      margin: 10px 0px;
      display: flex;
      align-items: center;
  }
  
  .featuredMoney{
      font-size: 30px;
      font-weight: 600;
  }
  
  .featuredMoneyRate{
      display: flex;
      align-items: center;
      margin-left: 20px;
  }
  
  .featuredIcon{
      font-size: 14px;
      margin-left: 5px;
      color: green;
  }
  
  .featuredIcon.negative{
      color: red;
  }
  
  .featuredSub{
      font-size: 15px;
      color: gray;
  }
.home{
    margin-top: 10px;
    /* flex: 4; */
    position: relative;
    left:15vw;
}

.homeWidgets{
    display: flex;
    margin: 20px;
}
.widgetSm{
    flex: 1 1;
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    padding: 20px;
    margin-right: 20px;
    background-color: white;
}

.widgetSmTitle{
    font-size: 22px;
    font-weight: 600;
}

.widgetSmImg{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.widgetSmList{
    margin: 0;
    padding: 0;
    list-style: none;
}

.widgetSmListItem{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0px;
}

.widgetSmUser{
    display: flex;
    flex-direction: column;
}

.widgetSmUsername{
    font-weight: 600;
}

.widgetSmUserTitle{
    font-weight: 300;
}

.widgetSmButton{
    display: flex;
    align-items: center;
    border: none;
    border-radius: 10px;
    padding: 7px 10px;
    background-color: #eeeef7;
    color: #555;
    cursor: pointer;
}

.widgetSmIcon{
    font-size: 16px !important;
    margin-right: 5px;
}
.widgetLg {
    flex: 2 1;
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    padding: 20px;
    background-color: white;
  }
  
  .widgetLgTitle {
    font-size: 22px;
    font-weight: 600;
  }
  
  .widgetLgTable{
    width: 100%;
    border-spacing: 20px;
  }
  
  .widgetLgTh{
    text-align: left;
  }
  
  .widgetLgUser{
    display: flex;
    align-items: center;
    font-weight: 600;
  }
  
  .widgetLgImg {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
  }
  
  .widgetLgDate,.widgetLgAmount{
    font-weight: 300;
  }
  
  .widgetLgButton {
    padding: 5px 7px;
    border: none;
    border-radius: 10px;
  }
  
  .widgetLgButton.Approved{
      background-color: #e5faf2;
      color: #3bb077;
  }
  .widgetLgButton.Declined{
      background-color: #fff0f1;
      color: #d95087;
  
  }
  .widgetLgButton.Pending{
      background-color: #ebf1fe;
      color: #2a7ade;
  
  }
.audit-log {
  /* flex: 4; */
  margin-top: 10px;
  height:100%;
  position: relative;
  width:85vw;
  left:15vw;
}

.audit-log-header{
  margin:20px;
}

.filter-formControl{
  margin: 10px !important;
  min-width: 200px !important;
}
  
.userListUser {
  display: flex;
  align-items: center;
}

.userListImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.userListEdit{
    border: none;
    border-radius: 10px;
    padding: 5px 10px;
    background-color: #3bb077;
    color: white;
    cursor: pointer;
    margin-right: 20px;
}

.userListDelete{
    color: red;
    cursor: pointer;
}
.table{
    height: 65vh;
    margin: 20px;
    /* padding: 20px; */
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
}
.topbar{
    width: 100%;
    height: 50px;
    background-color: #44505D;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 999;
}

.topbarWrapper{
    height: 100%;
    padding: 0 20px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* justify-content:flex-end; */
}

.logo{
    font-weight: bold;
    height:60%;
    /* align-self: middle; */
    /* font-size: 30px; */
    cursor: pointer;
}

  .topLeft{
  background-color: #182636;
  height: 50px;
  width:15vw;
  /* padding:5%; */
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.topRight{
    display: flex;
    align-items: center;
}

.topbarIconContainer{
    position: relative;
    cursor: pointer;
    margin-right: 10px;
    color: #5555;
}

.topIconBadge{
    width: 15px;
    height: 15px;
    position: absolute;
    top: -5px;
    right: 5px;
    background-color: red;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
}
.sidebar {
    /* flex: 1; */
    /* display:inline-block; */
    width:15vw;
    /* height: calc(100vh - 50px); */
    height:100vh;
    background-color: #182636;
    position: fixed;
    /* top: 50px; */
  }
  
  .sidebarWrapper {
    /* padding: 20px; */
    color: white;
  }
  
  .sidebarMenu {
    margin-bottom: 10px;
  }
  
  .sidebarTitle {
    font-size: 20px;
    color: rgb(187, 186, 186);
  }
  
  .sidebarList {
    list-style: none;
    padding: 0;
    font-size: 20px;
    font-weight: 600;
    border-top:1px solid #3E5269;
  }
  
  .sidebarListItem {
    padding: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-bottom:1px solid #3E5269;
    /* border-radius: 10px; */
  }
  
  .sidebarListItem.active,
  .sidebarListItem:hover {
    background-color:#3E5269;
    /* background-color: rgb(240, 240, 255); */
  }
  
  .sidebarIcon{
      margin-right: 10px;
      font-size: 20px !important;
  }

.logo-bottom{
  margin-bottom:1em;
  box-sizing: border-box;
  width:15vw;
  color:#788491;
  position:fixed;
  bottom: 0;
  height:50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.zImage{
  height:80%;
}

.zText{
  /* height:80%; */
  color:white;
  font-size: x-large;
  font-family: 'Varela Round', sans-serif;
  display:flex;
  justify-content: center;
  align-items: center;
}
.notfound-container {
	min-height: 100vh;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
