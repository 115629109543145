.callback{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 25%;
    margin: auto;
    height: 100vh;
}

.callback-loading-text{
    font-size: 15px;
    font-family: monospace;
    margin-bottom: 6px;
}