@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
.sidebar {
    /* flex: 1; */
    /* display:inline-block; */
    width:15vw;
    /* height: calc(100vh - 50px); */
    height:100vh;
    background-color: #182636;
    position: fixed;
    /* top: 50px; */
  }
  
  .sidebarWrapper {
    /* padding: 20px; */
    color: white;
  }
  
  .sidebarMenu {
    margin-bottom: 10px;
  }
  
  .sidebarTitle {
    font-size: 20px;
    color: rgb(187, 186, 186);
  }
  
  .sidebarList {
    list-style: none;
    padding: 0;
    font-size: 20px;
    font-weight: 600;
    border-top:1px solid #3E5269;
  }
  
  .sidebarListItem {
    padding: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-bottom:1px solid #3E5269;
    /* border-radius: 10px; */
  }
  
  .sidebarListItem.active,
  .sidebarListItem:hover {
    background-color:#3E5269;
    /* background-color: rgb(240, 240, 255); */
  }
  
  .sidebarIcon{
      margin-right: 10px;
      font-size: 20px !important;
  }

.logo-bottom{
  margin-bottom:1em;
  box-sizing: border-box;
  width:15vw;
  color:#788491;
  position:fixed;
  bottom: 0;
  height:50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.zImage{
  height:80%;
}

.zText{
  /* height:80%; */
  color:white;
  font-size: x-large;
  font-family: 'Varela Round', sans-serif;
  display:flex;
  justify-content: center;
  align-items: center;
}