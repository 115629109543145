.home{
    margin-top: 10px;
    /* flex: 4; */
    position: relative;
    left:15vw;
}

.homeWidgets{
    display: flex;
    margin: 20px;
}