@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');

/* .login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
} */

.login-wrapper {
    flex: 4;
    text-align: center;
    background-color: #182636;
    height:100vh;
    width:100vw;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-card {
  /* margin: auto;
  position: absolute;
  top: 50%; left: 0; bottom: 0; right: 0; */
  width:20vw;
}

.login-card > *{
  margin:1.5em 0;
}

.white-text{
  color:white;
}

.orange-link{
  color:#EEA83B;
}

.bolder{
  font-weight:bolder;
}

.agilityLogo{
  width:70%;
  display: block;
  margin:auto;
}
.zbrains-logo{
  height:30px;
}

.zbrains{
  /* height:100px; */
  /* color:white; */
  font-family: 'Varela Round', sans-serif;
  display:flex;
  justify-content: center;
  align-items: center;
  /* line-height: 100px;
  vertical-align: middle;
  text-align: center; */
}

.zbrains .bigger{
  font-size: large;
}

.login-featured {
    width: 25%;
    display: flex;
    justify-content: space-between;
    height: 25vh;
  }
  
  .login-featuredItem {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    flex: 1;
    margin: 0px 20px;
    padding: 30px;
    border-radius: 10px;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  }

  .login-title{
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 40px;
      font-family: fantasy;
  }

.login-button {
    background-color: #5BA850;
    text-align: center;
    border: none;
    border-radius: 5px;
    color: white;
    padding: 15px 32px;
    text-decoration: none;
    font-size: 16px;
    letter-spacing: 2px;
    font-weight: bolder;
    cursor: pointer;
    border-radius: 3px;
  }

  .login-button:hover {
      background-color: #3e7337;
  }

  .error{
      font-size: 0.8rem;
      color: #bb0000;
  }