.audit-log {
  /* flex: 4; */
  margin-top: 10px;
  height:100%;
  position: relative;
  width:85vw;
  left:15vw;
}

.audit-log-header{
  margin:20px;
}

.filter-formControl{
  margin: 10px !important;
  min-width: 200px !important;
}
  
.userListUser {
  display: flex;
  align-items: center;
}

.userListImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.userListEdit{
    border: none;
    border-radius: 10px;
    padding: 5px 10px;
    background-color: #3bb077;
    color: white;
    cursor: pointer;
    margin-right: 20px;
}

.userListDelete{
    color: red;
    cursor: pointer;
}