
.container{
    display: flex;
    background-color: #F1F4F5;
    /* display: inline-block; */
    /* margin-top: 10px; */
}

.white-background{
  background-color:white;
}

.link{
    text-decoration: none;
    color: inherit;
}