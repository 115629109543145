.topbar{
    width: 100%;
    height: 50px;
    background-color: #44505D;
    position: sticky;
    top: 0;
    z-index: 999;
}

.topbarWrapper{
    height: 100%;
    padding: 0 20px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* justify-content:flex-end; */
}

.logo{
    font-weight: bold;
    height:60%;
    /* align-self: middle; */
    /* font-size: 30px; */
    cursor: pointer;
}

  .topLeft{
  background-color: #182636;
  height: 50px;
  width:15vw;
  /* padding:5%; */
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.topRight{
    display: flex;
    align-items: center;
}

.topbarIconContainer{
    position: relative;
    cursor: pointer;
    margin-right: 10px;
    color: #5555;
}

.topIconBadge{
    width: 15px;
    height: 15px;
    position: absolute;
    top: -5px;
    right: 5px;
    background-color: red;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
}